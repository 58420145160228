
import { Vue, Options } from 'vue-class-component';
import get from 'lodash-es/get';
import {
  BaseIcon,
  SpecialityButton,
  BaseModal,
  PopoverLink,
  LockConsultModal,
  ReviewSummary
} from '@/lib/components';
import RejectReasonModal from '@/lib/components/Modals/RejectReasonModal.vue';
import ResolveReasonModal from '@/lib/components/Modals/ResolveReasonModal.vue';
import PatientLetter from '@/views/image-review/PatientLetter.vue';
import PatientSupportingDocumentModal from '@/views/patient/PatientSupportingDocumentModal.vue';
import AssignConsultModal from '@/views/worklists/AssignConsultModal.vue';
import CreateConsultModal from '@/views/worklists/CreateConsultModal.vue';
import { Clinic, Consult, Patient } from '@/models';
import { useSessionStore } from '@/stores/session.store';

@Options({
  props: {
    patient: {
      type: Object,
      required: true
    },
    canEditPatientMrn: {
      type: Boolean,
      default: false
    },
    consult: {
      type: Object,
      default: () => ({})
    },
    selectedReviewId: {
      type: String,
      default: ''
    },
    selectedDocumentId: {
      type: String,
      default: ''
    },
    clinicConfigurationId: {
      type: String,
      default: ''
    },
    consultCompletedAt: {
      type: String,
      default: ''
    },
    selectedFormSubmissionId: {
      type: String,
      default: ''
    },
    selectedFormSubmissionClinician: {
      type: String,
      default: ''
    },
    selectedClinic: {
      type: Object,
      default: () => ({})
    },
    clinicTypeOptions: {
      type: Array,
      default: () => []
    },
    assignConsultModalVisible: {
      type: Boolean,
      default: false
    },
    rejectedReasonModalVisible: {
      type: Boolean,
      default: false
    },
    resolveReasonModalVisible: {
      type: Boolean,
      default: false
    },
    scheduleConsultModalVisible: {
      type: Boolean,
      default: false
    },
    viewAndCopyReviewSummaryVisible: {
      type: Boolean,
      default: false
    },
    viewLetter: {
      type: Boolean,
      default: false
    },
    rejectReasonError: {
      type: String,
      default: ''
    },
    resolveReasonError: {
      type: String,
      default: ''
    }
  },
  components: {
    BaseIcon,
    SpecialityButton,
    BaseModal,
    ReviewSummary,
    PopoverLink,
    LockConsultModal,
    RejectReasonModal,
    ResolveReasonModal,
    PatientLetter,
    PatientSupportingDocumentModal,
    AssignConsultModal,
    CreateConsultModal
  }
})
export default class PatientConsultsModalWrapper extends Vue {
  patient!: Patient;
  consult!: Consult;
  sessionStore = useSessionStore();

  clinicConfigurationId!: string;
  selectedReviewId!: string;
  selectedDocumentId!: string;
  assignConsultModalVisible!: boolean;
  rejectedReasonModalVisible!: boolean;
  resolveReasonModalVisible!: boolean;
  scheduleConsultModalVisible!: boolean;
  viewLetter!: boolean;
  selectedClinic!: Clinic;

  viewAndCopyReviewSummaryVisible!: boolean;
  rejectReasonError!: string;
  resolveReasonError!: string;
  modalTargetPatientId = '';
  modalTargetReviewId = '';
  modalTargetLockedByUserFullName = '';
  isModalOpen = false;

  consultCompletedAt = '';
  selectedFormSubmissionId = '';
  selectedFormSubmissionClinician = '';

  get providerAddress() {
    return get(this.consult.clinic, 'provider.address.formatted_address', '');
  }

  hasPermission(permission: string) {
    return this.sessionStore.permissions.includes(permission);
  }

  closeModal() {
    this.modalTargetPatientId = '';
    this.modalTargetReviewId = '';
    this.modalTargetLockedByUserFullName = '';
    this.isModalOpen = false;
  }
}
