import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReviewSummary = _resolveComponent("ReviewSummary")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_PatientLetter = _resolveComponent("PatientLetter")!
  const _component_PatientSupportingDocumentModal = _resolveComponent("PatientSupportingDocumentModal")!
  const _component_CreateConsultModal = _resolveComponent("CreateConsultModal")!
  const _component_AssignConsultModal = _resolveComponent("AssignConsultModal")!
  const _component_LockConsultModal = _resolveComponent("LockConsultModal")!
  const _component_RejectReasonModal = _resolveComponent("RejectReasonModal")!
  const _component_ResolveReasonModal = _resolveComponent("ResolveReasonModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.viewAndCopyReviewSummaryVisible)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 0,
          class: "inline-block bg-white",
          "vertical-align": "center",
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeViewAndCopyReviewSummaryModel')))
        }, {
          default: _withCtx(() => [
            (_ctx.selectedReviewId)
              ? (_openBlock(), _createBlock(_component_ReviewSummary, {
                  key: 0,
                  patient: _ctx.patient,
                  "form-submission-id": _ctx.selectedFormSubmissionId,
                  clinician: _ctx.selectedFormSubmissionClinician,
                  "review-id": _ctx.selectedReviewId,
                  "clinic-name": _ctx.consult.clinic.name,
                  "clinic-address": _ctx.providerAddress,
                  "consult-completed-at": _ctx.consultCompletedAt,
                  onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeViewAndCopyReviewSummaryModel')))
                }, null, 8, ["patient", "form-submission-id", "clinician", "review-id", "clinic-name", "clinic-address", "consult-completed-at"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.viewLetter)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 1,
          class: "inline-block bg-white",
          "vertical-align": "center",
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('closeLetter')))
        }, {
          default: _withCtx(() => [
            (_ctx.selectedReviewId)
              ? (_openBlock(), _createBlock(_component_PatientLetter, {
                  key: 0,
                  style: {"height":"calc(100vh - 48px)","width":"calc(100vh - 48px)"},
                  "patient-id": _ctx.patient.id,
                  "review-id": _ctx.selectedReviewId,
                  onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeLetter')))
                }, null, 8, ["patient-id", "review-id"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.selectedDocumentId)
      ? (_openBlock(), _createBlock(_component_PatientSupportingDocumentModal, {
          key: 2,
          "document-id": _ctx.selectedDocumentId,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('closeSupportingDocumentModal')))
        }, null, 8, ["document-id"]))
      : _createCommentVNode("", true),
    (_ctx.assignConsultModalVisible && _ctx.hasPermission('consult:create'))
      ? (_openBlock(), _createBlock(_component_CreateConsultModal, {
          key: 3,
          title: _ctx.$t('custom.uhb.consult.assign'),
          "patient-id": _ctx.patient.id,
          "clinic-configuration-id": _ctx.clinicConfigurationId,
          "clinic-type-options": _ctx.clinicTypeOptions,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('closeAssignConsultModal')))
        }, null, 8, ["title", "patient-id", "clinic-configuration-id", "clinic-type-options"]))
      : _createCommentVNode("", true),
    (_ctx.scheduleConsultModalVisible && _ctx.hasPermission('consult:schedule'))
      ? (_openBlock(), _createBlock(_component_AssignConsultModal, {
          key: 4,
          title: _ctx.$t('custom.uhb.consult.schedule-consult'),
          patient: _ctx.patient,
          consult: _ctx.consult,
          "can-edit-patient-mrn": _ctx.canEditPatientMrn,
          "clinic-type-options": _ctx.clinicTypeOptions,
          onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('closeScheduleConsultModal'))),
          onUpdatePatient: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('update-patient')))
        }, null, 8, ["title", "patient", "consult", "can-edit-patient-mrn", "clinic-type-options"]))
      : _createCommentVNode("", true),
    (_ctx.isModalOpen)
      ? (_openBlock(), _createBlock(_component_LockConsultModal, {
          key: 5,
          title: _ctx.$t('custom.uhb.review.unlock'),
          message: 
        _ctx.$t('custom.uhb.review.unlock-message', {
          user: _ctx.modalTargetLockedByUserFullName,
        })
      ,
          onClose: _ctx.closeModal,
          onExit: _cache[8] || (_cache[8] = ($event: any) => (_ctx.unlockConsult(_ctx.modalTargetPatientId, _ctx.modalTargetReviewId)))
        }, null, 8, ["title", "message", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.rejectedReasonModalVisible && _ctx.consult)
      ? (_openBlock(), _createBlock(_component_RejectReasonModal, {
          key: 6,
          patient: _ctx.patient,
          consult: _ctx.consult,
          error: _ctx.rejectReasonError,
          onMarkAsRejected: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('markAsRejected', $event))),
          onUpdateErrorMessage: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('updateRejectErrorMessage', $event))),
          onClose: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('closeRejectedReasonModal')))
        }, null, 8, ["patient", "consult", "error"]))
      : _createCommentVNode("", true),
    (_ctx.resolveReasonModalVisible && _ctx.consult)
      ? (_openBlock(), _createBlock(_component_ResolveReasonModal, {
          key: 7,
          patient: _ctx.patient,
          consult: _ctx.consult,
          error: _ctx.resolveReasonError,
          onMarkAsResolved: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('markAsResolved', $event))),
          onUpdateErrorMessage: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('updateResolveErrorMessage', $event))),
          onClose: _cache[14] || (_cache[14] = ($event: any) => (_ctx.$emit('closeResolveReasonModal')))
        }, null, 8, ["patient", "consult", "error"]))
      : _createCommentVNode("", true)
  ]))
}